import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {motion, useSpring} from 'framer-motion'
import {ColorPresence, color, scale} from 'core/theme'

const StyledLink = styled.a`
  display: block;
  margin-left: ${rem(scale[3])};
  padding: 0;
`

export default function ShopLink({translate}) {
  const {color: presenceColor} = React.useContext(ColorPresence)
  const circleColor = useSpring(color.brand.yellow, {damping: 50})

  React.useEffect(() => {
    return presenceColor.onChange(latestpresenceColor => {
      if (latestpresenceColor === 'rgba(247, 213, 72, 1)' /* brand.yellow */) {
        circleColor.set(color.brand.blue)
      } else {
        circleColor.set(color.brand.yellow)
      }
    })
  }, [presenceColor, circleColor])

  return (
    <StyledLink
      href='https://pienaveikals.lv'
      target='_self'
      title={translate('Iet uz "Piena Veikals"')}
    >
      <svg
        width='44'
        height='44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0)'>
          <motion.circle cx='22' cy='22' r='22' style={{fill: circleColor}} />
          <path
            d='M9.917 12.917h3.666L16.04 25.19a1.833 1.833 0 001.833 1.476h8.91a1.834 1.834 0 001.834-1.476l1.466-7.691H17.25'
            stroke='#fff'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M16.88 32.343a1.463 1.463 0 100-2.927 1.463 1.463 0 000 2.927zM27.88 32.343a1.463 1.463 0 100-2.926 1.463 1.463 0 000 2.926z'
            stroke='#fff'
            strokeWidth='1.5'
            strokeMiterlimit='10'
            strokeLinecap='round'
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <path fill='#fff' d='M0 0h44v44H0z' />
          </clipPath>
        </defs>
      </svg>
    </StyledLink>
  )
}
