import React from 'react'
import {useAbortableFetch} from 'core/hooks'
import {LanguageContext} from 'core/language'
import NavBar from './NavBar'

const primaryMenu = {
  lv: 9,
  ru: 13,
}

export default function NavBarConnected() {
  const {activeLang, defaultLang, availableLangs} = React.useContext(
    LanguageContext
  )
  const {data} = useAbortableFetch(`/api/menus/${primaryMenu[activeLang]}`)

  const homeLink = activeLang === defaultLang ? '/' : `/${activeLang}`
  const toggleLang = availableLangs.find(lang => lang !== activeLang)
  const toggleHomeLink = toggleLang === defaultLang ? '/' : `/${toggleLang}`

  if (data) {
    const langNav = {
      lang: toggleLang,
      homeLink: toggleHomeLink,
    }

    return (
      <NavBar
        activeLang={activeLang}
        defaultLang={defaultLang}
        homeLink={homeLink}
        langNav={langNav}
        pages={data.items}
      />
    )
  }

  return null
}
