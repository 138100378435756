import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {motion} from 'framer-motion'
import {stripHTML} from 'utils/entities'
import {scale} from 'core/theme'
import {Icon, IconButton} from './'

function openSocialWindow(url) {
  const width = 570
  const height = 570
  const left = (window.screen.width - width) / 2
  const top = (window.screen.height - height) / 2
  const params = `menubar=no,toolbar=no,status=no,width=${width},height=${height},top=${top},left=${left}`
  window.open(url, 'NewWindow', params)
}

const actions = [
  {
    id: 'linkedin',
    title: 'Dalīties LinkedIn',
    share: post => {
      const url = encodeURIComponent(window.location.href)
      const shareTitle = encodeURIComponent(post.title)
      const shareSummary = encodeURIComponent(
        post.excerpt
          ? stripHTML(post.excerpt)
          : stripHTML(post.content).substr(0, 140) + '…'
      )
      const siteName = 'Food Union Latvia'
      openSocialWindow(
        `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${shareTitle}&summary=${shareSummary}&source=${siteName}`
      )
    },
  },
  {
    id: 'facebook',
    title: 'Dalīties Facebook',
    share: post => {
      const url = encodeURIComponent(window.location.href)
      openSocialWindow(`https://www.facebook.com/sharer.php?u=${url}`)
    },
  },
  {
    id: 'twitter',
    title: 'Dalīties Twitter',
    share: post => {
      const url = encodeURIComponent(window.location.href)
      const shareText = encodeURIComponent(
        post.excerpt
          ? stripHTML(post.excerpt)
          : post.title + '. ' + stripHTML(post.content).substr(0, 100) + '…'
      )
      openSocialWindow(
        `https://twitter.com/intent/tweet?url=${url}&text=${shareText}`
      )
    },
  },
  // {
  //   id: 'email',
  //   title: 'Send via e-mail',
  // },
  // {
  //   id: 'print',
  //   title: 'Print article',
  // },
]

const StyledList = styled(motion.ul)`
  flex-direction: row;

  > li button {
    margin: -0.75em;
  }

  > li + li {
    margin-left: ${rem(scale[2])};
  }
`

const listVariants = {
  enter: {
    transition: {staggerChildren: 0.05},
  },
}

const itemVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {type: 'spring', stiffness: 200, damping: 15},
  },
}

const itemHover = {
  y: -5,
  transition: {type: 'spring', stiffness: 200},
}

export default function ShareActionsList({data, translate}) {
  const post = Array.isArray(data) && data.length === 1 ? data[0] : data
  return (
    <StyledList initial='initial' animate='enter' variants={listVariants}>
      {actions.map(({id, title, share}) => (
        <motion.li key={id} variants={itemVariants} whileHover={itemHover}>
          <IconButton
            title={translate(title)}
            onClick={() => {
              share && share(post)
            }}
          >
            <Icon id={id} />
          </IconButton>
        </motion.li>
      ))}
    </StyledList>
  )
}
