import React from 'react'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {motion} from 'framer-motion'
import {color, scale} from 'core/theme'
import {useTranslation} from 'core/language'
import {ReactComponent as Arrow} from 'icons/assets/arrow.svg'

const StyledButton = styled.button.attrs({
  type: 'button',
})`
  color: ${color.text.green};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  font-size: ${rem(12)};
  font-weight: 700;
  margin: -1em;
  padding: 1em;
  text-transform: uppercase;

  > i {
    display: inline-block;
    margin-left: ${rem(scale[1])};
  }

  svg {
    display: block;
    transform: scaleX(-1);
  }

  &:focus {
    outline: 0;
  }
`

export default function ReadMore() {
  const [isHover, setIsHover] = React.useState(false)
  const translate = useTranslation()

  return (
    <StyledButton
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      aria-hidden='true'
      tabIndex={-1}
    >
      <span>{translate('Lasīt vairāk')}</span>
      <motion.i
        initial={{x: 0}}
        animate={
          isHover
            ? {x: 10, transition: {type: 'spring'}}
            : {x: 0, transition: {type: 'spring'}}
        }
      >
        <Arrow />
      </motion.i>
    </StyledButton>
  )
}
