import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import styled from 'styled-components/macro'
import {rem} from 'polished'
import {motion} from 'framer-motion'
import {color, media, scale} from 'core/theme'
import {ReactComponent as Arrow} from 'icons/assets/arrow.svg'

const Wrapper = styled(motion.div)`
  overflow: hidden;

  > div {
    display: inline-block;
  }
`

const StyledLink = styled(Link)`
  color: ${color.text.default};
  display: inline-flex;
  align-items: center;
  font-size: ${rem(16)};
  padding: ${rem(scale[1])} ${rem(scale[2])};
  text-decoration: none;

  > span {
    display: inline-block;
    margin-left: 1em;
  }

  ${media.laptop`
    margin-left: ${rem(scale[5])};
  `};
`

const variants = {
  initial: {x: 50, opacity: 0},
  enter: {
    x: 0,
    opacity: 1,
    transition: {delay: 0.5, type: 'spring', stiffness: 150, damping: 15},
  },
}

export default function BackLink({label, backPath}) {
  const [isHover, setIsHover] = React.useState(false)

  const {pathname, state} = useLocation()
  const path = backPath || pathname.substr(0, pathname.lastIndexOf('/'))

  let searchParams = ''
  if (state && state.params) {
    const {year} = state.params
    if (year) {
      searchParams = `?year=${year}`
    }
  }

  return (
    <Wrapper>
      <motion.div
        onHoverStart={() => setIsHover(true)}
        onHoverEnd={() => setIsHover(false)}
        variants={variants}
      >
        <StyledLink
          to={{
            pathname: path,
            search: searchParams,
            state: state && state.activeFilters
              ? {
                  activeFilters: state.activeFilters,
                }
              : {},
          }}
        >
          <motion.i
            initial={{x: 0}}
            animate={
              isHover
                ? {x: -10, transition: {type: 'spring'}}
                : {x: 0, transition: {type: 'spring'}}
            }
          >
            <Arrow />
          </motion.i>
          <span>{label}</span>
        </StyledLink>
      </motion.div>
    </Wrapper>
  )
}
