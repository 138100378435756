import React from 'react'
import styled, {keyframes} from 'styled-components/macro'
import {rem} from 'polished'
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie'
import {color, ease, media, scale, ColorPresence} from 'core/theme'
import {LanguageContext, useTranslation} from 'core/language'
import {ContentWidth} from 'elements'
import {ActionButton} from 'modules/Article/elements'

const slideIn = keyframes`
  to {
    transform: translateY(0);
  }
`

const StyledInfoBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 101;

  background-color: ${color.brand.deepBlue};
  color: ${color.white};
  padding: ${rem(scale[3])} 0;

  will-change: transform;
  transform: translateY(110%);
  animation: ${slideIn} 500ms ${ease.inOut} 2s;
  animation-fill-mode: forwards;

  > div {
    display: flex;
    flex-direction: column;
    font-size: 1rem;

    > p {
      line-height: 150%;
      margin-top: 0;
      margin-bottom: ${rem(scale[2])};

      > br {
        display: none;
      }

      > a {
        color: inherit;
        margin: -0.5em;
      }
    }

    > button {
      color: inherit;
      border-color: inherit;
      width: 100%;

      &:hover {
        background-color: ${color.white};
        color: ${color.brand.deepBlue};
      }
    }
  }

  ${media.tablet`
    > div {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > p {
        margin-right: ${rem(scale[2])};
        margin-bottom: 0;

        > br {
          display: inline;
        }
      }

      > button {
        width: auto;
      }
    }
  `};
`

const COOKIE_EXPIRES = 30
const COOKIE_NAME = 'cookies-accepted'

export default function CookieConsent() {
  const {color: colorPresence, saturation} = React.useContext(ColorPresence)

  const cookieValue = Cookies.get(COOKIE_NAME)
  const isTruthy = cookieValue && parseInt(cookieValue, 10) === 1
  const [acceptedCookies, setAcceptedCookies] = React.useState(isTruthy)

  const translate = useTranslation()
  const {activeLang, defaultLang} = React.useContext(LanguageContext)

  function onAcceptCookies() {
    Cookies.set(COOKIE_NAME, 1, {expires: COOKIE_EXPIRES})
    setAcceptedCookies(true)
  }

  function resetColors() {
    colorPresence.set('#ffffff')
    saturation.set(0)
  }

  if (acceptedCookies) {
    return null
  }

  const keyword = translate('šeit')
  let text = translate(
    'Šajā mājaslapā tiek izmantotas sīkdatnes (Cookies), lai nodrošinātu Jums ērtāku un drošāku mājaslapas lietošanu. Lūdzu, iepazīstieties ar sīkdatņu izmantošanu šeit un apstipriniet savu piekrišanu sīkdatņu izmantošanai.'
  )
  text = text.split(keyword)

  const langPath = activeLang === defaultLang ? '' : `/${activeLang}`

  return (
    <StyledInfoBanner>
      <ContentWidth>
        <p>
          {text[0]}
          <Link
            to={`${langPath}/${translate('privatuma-politika')}`}
            onClick={resetColors}
          >
            {keyword}
          </Link>
          {text[1]}
        </p>

        <ActionButton onClick={onAcceptCookies}>
          {translate('Piekrītu')}
        </ActionButton>
      </ContentWidth>
    </StyledInfoBanner>
  )
}
