import React from 'react'
import {ReactComponent as FacebookIcon} from 'modules/Article/assets/platform-icons/Facebook.svg'
import {ReactComponent as InstagramIcon} from 'modules/Article/assets/platform-icons/Instagram.svg'
import {ReactComponent as LinkedInIcon} from 'modules/Article/assets/platform-icons/LinkedIn.svg'
import {ReactComponent as TwitterIcon} from 'modules/Article/assets/platform-icons/Twitter.svg'
import {ReactComponent as WebsiteIcon} from 'modules/Article/assets/platform-icons/Website.svg'
import {ReactComponent as YouTubeIcon} from 'modules/Article/assets/platform-icons/YouTube.svg'

import {ReactComponent as DownloadIcon} from 'modules/Article/assets/action-icons/Download.svg'
import {ReactComponent as EmailIcon} from 'modules/Article/assets/action-icons/E-mail.svg'
import {ReactComponent as AddressIcon} from 'modules/Article/assets/action-icons/Location.svg'
import {ReactComponent as PhoneIcon} from 'modules/Article/assets/action-icons/Phone.svg'
import {ReactComponent as PhotoIcon} from 'modules/Article/assets/action-icons/Photo.svg'
import {ReactComponent as PrintIcon} from 'modules/Article/assets/action-icons/Print.svg'
import {ReactComponent as UploadIcon} from 'modules/Article/assets/action-icons/Upload.svg'
import {ReactComponent as VideoIcon} from 'modules/Article/assets/action-icons/Video.svg'
import {ReactComponent as CloseIcon} from 'modules/Article/assets/action-icons/Close.svg'

export default function Icon({id, as, ...props}) {
  const Wrapper = as ? as : 'i'
  const icon = React.useMemo(() => {
    switch (id) {
      case 'facebook':
        return <FacebookIcon />
      case 'instagram':
        return <InstagramIcon />
      case 'linkedin':
        return <LinkedInIcon />
      case 'twitter':
        return <TwitterIcon />
      case 'website':
        return <WebsiteIcon />
      case 'youtube':
        return <YouTubeIcon />

      case 'download':
        return <DownloadIcon />
      case 'email':
        return <EmailIcon />
      case 'address':
        return <AddressIcon />
      case 'phone':
        return <PhoneIcon />
      case 'photo':
        return <PhotoIcon />
      case 'print':
        return <PrintIcon />
      case 'upload':
        return <UploadIcon />
      case 'video':
        return <VideoIcon />

      case 'close':
        return <CloseIcon />

      default:
        return null
    }
  }, [id])
  return <Wrapper {...props}>{icon}</Wrapper>
}
